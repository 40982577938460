/* FIXME: Global CSS Rules */
strong,
b,
th,
h1,
h2,
h3,
h4,
h5,
h6 {
  --font-weight: 600;
}

/******custom styles******/

a {
  text-decoration: none;
}

ion-menu::part(container) {
  border-bottom-right-radius: 2rem;
  height: 90%;
}

ion-menu,
ion-menu ion-content,
ion-menu ion-item {
  --background: var(--ion-color-custom-overlay);
}

ion-modal {
  --height: 97%;
  --border-radius: 1.5rem;
  align-items: end;
}

ion-modal.no-border-radius {
  --border-radius: 0;
}
ion-modal.h-100 {
  --height: 100%;
}

.custom-button-radius {
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  --border-radius: var(--button-border-radius);
  height: var(--button-height);
  min-width: var(--button-min-width);
  text-transform: none;
}

ion-fab-button {
  --box-shadow: var(--button-box-shadow);
}

ion-toolbar {
  --background: var(--toolbar-background);
}

ion-label {
  font-size: var(--ion-label-font-size) !important;
}

ion-item {
  --min-height: 70px;
}
ion-item.min-height-auto {
  --min-height: auto !important;
}

ion-item[slot='header'] ion-label {
  font-size: calc(32 / var(--root-size) * 1rem) !important;
  font-weight: 600;
  padding-inline-start: 10px;
}

ion-menu ion-item ion-label {
  font-size: calc(20 / var(--root-size) * 1rem) !important;
}

ion-progress-bar {
  border-radius: calc(12 / var(--root-size) * 1rem);
}

/* layout elements */

ion-skeleton-text {
  --border-radius: 10px;
}

ion-grid.ion-padding-horizontal {
  padding-left: 32px;
  padding-right: 32px;
}

ion-list.skeleton-list {
  padding-right: 20px;
}

ion-list.skeleton-list ion-list-header {
  padding: 0;
  margin-bottom: 1rem;
}

ion-list.skeleton-list ion-item {
  margin-bottom: 1rem;
}

ion-list.skeleton-list ion-item ion-skeleton-text {
  margin-bottom: 1rem;
}

.skeleton-checkbox {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  --border-radius: 50%;
}

/*form Fields*/

ion-checkbox {
  --size: var(--checkbox-size);
  --border-color: var(--ion-color-primary);
  --border-radius: 50%;
}

ion-checkbox[slot='start'],
[slot='start'] > ion-checkbox {
  align-self: flex-start;
  margin-top: calc(20 / var(--root-size) * 1rem);
  margin-bottom: 0;
  margin-inline-start: calc(3 / var(--root-size) * 1rem);
  margin-inline-end: calc(13 / var(--root-size) * 1rem);
}

ion-checkbox.custom-checkbox {
  --checkmark-width: 0;
}

ion-checkbox.custom-checkbox::part(container) {
  background-image: var(--ct_checkmark-image);
  background-repeat: no-repeat;
  background-position: center;
}

ion-checkbox.custom-checkbox__photoRequired::part(container) {
  background-image: url('../assets/images/icons/Camera-outline.svg');
  background-repeat: no-repeat;
  background-position: center;
}

ion-item.custom-checkbox {
  margin-top: calc(3 / var(--root-size) * 1rem);
  margin-bottom: calc(3 / var(--root-size) * 1rem);
  --inner-border-width: 0;
  --border-width: calc(1 / var(--root-size) * 1rem);
  --border-radius: calc(8 / var(--root-size) * 1rem);
  --border-color: transparent;
  --padding-top: calc(2 / var(--root-size) * 1rem);
  --padding-bottom: calc(2 / var(--root-size) * 1rem);
}

ion-checkbox.multi-select {
  --size: calc(36 / var(--root-size) * 1rem);
  --border-radius: calc(6 / var(--root-size) * 1rem);
  --border-width: calc(3 / var(--root-size) * 1rem);
  --border-color: #c0c7cd;
  --checkmark-color: var(--ion-color-primary);
}

ion-checkbox.multi-select::part(mark) {
  display: none;
}

ion-checkbox.multi-select::part(container) {
  padding: 0.125rem;
  background-clip: content-box;
  background-image: none;
}

ion-checkbox.multi-select.checkbox-indeterminate::part(mark) {
  display: initial;
  --checkmark-width: 4px;
}

ion-checkbox.multi-select.checkbox-indeterminate::part(container) {
  background: transparent;
}

ion-checkbox[slot='start'].multi-select {
  margin-inline-start: calc(2 / var(--root-size) * 1rem);
  margin-inline-end: calc(12 / var(--root-size) * 1rem);
  margin-top: calc(19 / var(--root-size) * 1rem);
}

ion-checkbox.animated-checkbox::after {
  display: block;
  content: '';
  position: relative;
  opacity: 0;
  height: 5px;
  width: 5px;
  bottom: 19px;
  left: 13.5px;
  border-radius: 50%;
  z-index: -1;
}

ion-item.custom-checkbox.multi-select {
  --padding-bottom: calc(4 / var(--root-size) * 1rem);
}

ion-item.multi-select-selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.11);
  --border-color: var(--ion-color-primary);
}

ion-input.custom-input,
ion-textarea.custom-input {
  --border-radius: calc(20 / var(--root-size) * 1rem);
  --background: #dce3e9;
}

ion-input {
  --padding-start: calc(var(--root-size) / var(--root-size) * 1rem) !important;
}

ion-input.centered-input {
  text-align: center;
  --padding-start: 0 !important;
  font-size: calc(20 / var(--root-size) * 1rem);
}

ion-item[slot='header'] ion-label {
  padding: 0 !important;
}

ion-textarea.custom-textarea-margin {
  margin-top: calc(22 / var(--root-size) * 1rem);
}

ion-input.no-border,
body.dark ion-input.no-border {
  border: none !important;
  border-bottom: none !important;
  border-radius: 0px !important;
}

ion-input.bottom-border,
body.dark ion-input.bottom-border {
  border: none !important;
  border-bottom: 1px solid #7e858a !important;
  border-radius: 0px !important;
  width: 100% !important;
}

ion-item-options {
  align-items: center;
}

ion-item-option {
  height: 70px;
}

.item-options-ios.item-options-end {
  border-bottom-width: 0;
}

/*custom buttons*/

ion-footer.action-buttons ion-button::part(native) {
  --border-radius: var(--button-circle-radius);
  width: 3rem !important;
  height: 3rem !important;
}

ion-footer.action-buttons ion-button {
  width: 3rem !important;
  height: 3rem !important;
}

ion-button[fill='clear'],
ion-button.custom-button {
  --background-hover: transparent !important;
  --background-activated: transparent !important;
  --color: var(--ion-color-primary) !important;
  --transition: opacity 0.2s !important;
}

ion-toast::part(button) {
  transition: opacity 0.2s !important;
}

ion-button[fill='clear']:active,
ion-button.custom-button:active {
  --opacity: 40%;
}

ion-toast::part(button):active {
  opacity: 40%;
}

ion-button.circle-button::part(native) {
  margin: 0 auto;
}

/*page elements*/

ion-header ion-toolbar {
  --background: var(--ion-background-color);
}

ion-header::after {
  display: none;
}

ion-header ion-title {
  padding: 0%;
}

ion-header ion-button {
  min-width: initial;
}

.custom-header {
  color: var(--ion-text-color);
}

/*overlay elements*/

ion-toast {
  transform: translateY(-145px);
  --color: var(--ion-color-dark);
  --background: var(--ion-color-overlay);
}

ion-toast::part(message) {
  text-align: center;
}

ion-toast::part(button) {
  color: var(--ion-color-primary);
}

/* Popovers */

ion-popover.popover-menu ion-content {
  --background: var(--ion-color-overlay);
}

ion-popover.popover-menu ion-content::part(background),
ion-popover.popover-menu ion-content::part(scroll) {
  border-radius: 7px;
}

ion-popover.popover-menu ion-item {
  --background: var(--ion-color-overlay);
  cursor: pointer;
}

ion-popover.offline-popover {
  --width: 80vw;
}

ion-popover.popover-menu__tags {
  /* There is an issue with ionic dynamic width/height popvovers, causing the popover to overflow from the page */
  /* Setting a fixed width and a height avoids the overflow issue */
  /* TODO: Delete this CSS rule and test if the photo tags popover overflow the screen after updating Ionic */
  --width: 180px;
  --height: 238.25px;
}

ion-popover.popover-menu.popover-menu__tags ion-item {
  --min-height: 3.25rem;
  cursor: pointer;
}

ion-action-sheet.custom-action-sheet {
  --background: var(--ion-color-overlay);
}

ion-action-sheet.custom-action-sheet .action-sheet-container {
  width: 90%;
  margin: auto;
}

ion-action-sheet.custom-action-sheet .action-sheet-group:first-child {
  border-radius: 10px 10px 0 0;
}

ion-action-sheet.custom-action-sheet .action-sheet-group-cancel span {
  justify-content: center;
}

ion-back-button::part(native) {
  min-height: 0 !important;
  height: auto;
}

/*top root styles*/
.Section__container___3YYTG {
  height: 100%;
}

/*custom dark*/

body.dark ion-input.custom-input,
body.dark ion-textarea.custom-input {
  --background: #41484d;
}

body.dark ion-item.multi-select-selected {
  --background: #202b30;
}

/*delete confirmation*/

.delete-confirmation .alert-head .alert-title {
  text-decoration: none;
  font-weight: normal;
  font-size: 1.6em;
}

.delete-confirmation .alert-message {
  white-space: pre-line;
}

.delete-confirmation .alert-wrapper {
  border-radius: 28px;
}

.delete-confirmation .alert-button-inner {
  text-transform: none;
}

.delete-confirmation .alert-button-role-destructive .alert-button-inner {
  color: var(--ion-color-error);
}

/* Clock in confirmation */
.clock-in-confirmation .alert-wrapper {
  border-radius: 28px;
  --max-width: 312px;
}

.clock-in-confirmation .alert-head .alert-title {
  text-decoration: none;
  font-weight: normal;
  font-size: 1.6rem;
}

.clock-in-confirmation.alert-message {
  white-space: pre-line;
}

.clock-in-confirmation .alert-button-group {
  flex-wrap: nowrap;
  flex-basis: revert;
  flex-flow: row-reverse;
  justify-content: space-between;
  padding: 0px 16px;
  padding-bottom: 20px;
}

.clock-in-confirmation .alert-button {
  margin: 0;
}

.clock-in-confirmation .alert-button-inner {
  text-transform: none;
}

.clock-in-confirmation .alert-button-role-destructive .alert-button-inner {
  color: var(--ion-color-error);
}

/*agreement confirmation*/

.agreement-confirmation .alert-head .alert-title {
  text-decoration: none;
  font-weight: 600;
  font-size: 1.8rem;
}

.agreement-confirmation .alert-message {
  white-space: pre-line;
  font-weight: 400;
  color: var(--ion-text-color, #000);
}

.agreement-confirmation .alert-wrapper {
  border-radius: 28px;
}

.agreement-confirmation .alert-button-group.sc-ion-alert-md {
  justify-content: space-between;
  padding-bottom: 1rem;
}

.agreement-confirmation .alert-button-inner {
  text-transform: none;
}

.agreement-confirmation .alert-button-role-cancel .alert-button-inner {
  color: #7a8388;
  font-size: 1rem;
}

.agreement-confirmation .alert-button-role-destructive .alert-button-inner {
  color: var(--ion-overlay-background-color, var(--ion-background-color, #fff));
  font-size: 1.2rem;
}

.agreement-confirmation .agreement-confirmation-accept {
  background-color: #5dcf96;
  padding: 0.7rem 1.6rem;
  border-radius: 100px;
}

/* Remove owner confirmation */

.remove-owner-confirmation .alert-wrapper {
  border-radius: 28px;
  padding: 8px 4px;
  max-width: 312px;
}

.remove-owner-confirmation .alert-head .alert-title {
  text-decoration: none;
  font-weight: 400;
  font-size: 1.8rem;
}

.remove-owner-confirmation .alert-message {
  font-size: 1rem;
}

.remove-owner-confirmation .alert-button-group {
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 8px;
}

.remove-owner-confirmation .alert-button-inner {
  text-transform: none;
  font-size: 1rem;
  text-align: center;
}

.remove-owner-confirmation .remomve-owner-confirmation__submit-button {
  color: white;
  background-color: #ff5449;
  padding: 10px 24px;
  border-radius: 16px;
  margin-bottom: 8px;
}

/* custom colors */
/** for adding custom colors, see here: https://ionicframework.com/docs/theming/colors#adding-colors **/
/** make sure to add both the light mode version and the dark mode version of the color
    (in the Ionic CSS Variables and Dark Colors sections, respectively, at the top of the file) **/

.ion-color-overlay {
  --ion-color-base: var(--ion-color-overlay);
  --ion-color-base-rgb: var(--ion-color-overlay-rgb);
  --ion-color-contrast: var(--ion-color-overlay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-overlay-contrast-rgb);
  --ion-color-shade: var(--ion-color-overlay-shade);
  --ion-color-tint: var(--ion-color-overlay-tint);
}

.ion-color-light-custom {
  /* We were having issues with applying this color to completed tasks in a way
      that would also color the ellipsis */
  color: var(--ion-color-light);
}

#completed {
  max-height: 100%;
  overflow: hidden;
  transition: all 300ms cubic-bezier(0.25, 0.8, 0.5, 1) 0s;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 160px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  height: 150px;
}

ion-thumbnail {
  --border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
}

.ion-btn-lg {
  width: 10rem;
}

.avatar-container {
  margin: auto;
  height: calc(280 / var(--root-size) * 1rem);
  width: calc(280 / var(--root-size) * 1rem);
}

.avatar-container ion-img::part(image) {
  border-radius: 50%;
}

.no-min-height {
  --min-height: 0 !important;
}

.no-hover ion-item {
  --background-hover: none;
}

/* Ion Refresher  Styles */

.custom-refresher ion-spinner {
  color: var(--ion-color-primary) !important;
}

/* Ion Datetime Styles */

ion-datetime {
  --background: var(--ion-color-ligth);
  --background-rgb: 255, 241, 242;

  box-shadow: rgba(var(--ion-color-light-rgb), 0.3) 0px 10px 15px -3px;

  --wheel-highlight-background: #006c452b;
  --wheel-fade-background-rgb: var(--ion-color-light-rgb);
}

ion-datetime-button::part(native) {
  background: var(--ion-color-overlay);
}

.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.date-grid ion-button.calendar-button {
  position: relative;
  --border: 0;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 0px;
  padding: 0px;
  border-radius: 50%;
  --background: transparent;
  --box-shadow: none;
  color: var(--ion-text-color);
}

.date-grid ion-button.calendar-button::part(native) {
  --border-radius: 50%;
}

/* Custom search input */

.custom-search-input {
  --padding-start: 0.25rem;
}

.ion-icon-thin ion-icon {
  --ionicon-stroke-width: 10px;
}

body.dark .primary-hover-effect:hover {
  transition: all 0.3s ease-in-out;
  color: #79e1ab;
}

.min-height-auto {
  --min-height: auto !important;
}

@media screen and (max-width: 450px) {
  /* Small devices (landscape phones, 450px and down) */
  html {
    font-size: 15px;
  }
  ion-footer ion-button.btn-md::part(native) {
    --padding-top: 1.5rem !important;
    --padding-bottom: 1.5rem !important;
    --padding-start: 1rem !important;
    --padding-end: 1rem !important;
  }
}

.ion-content-scroll-host {
  overflow-y: auto;
}

.ion-content-window-scroll-host {
  overflow-y: initial;
}

/* Photo Slider */
.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 12px)) !important;
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 12px)) !important;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px) !important;
  background-color: var(--ion-color-dark) !important;
}

/* Testing swiper navigation buttons */
/* .swiper-button-next,
.swiper-button-prev
{
  color: var(--ion-color-dark) !important;
  position: relative !important;
  margin-top: 0 !important;
}

.swiper-preview-pagination {
  width: fit-content !important;
  position: relative !important;
  top: unset !important;
  bottom: unset !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem !important;
}

.swiper-preview-pagination .swiper-pagination-bullet,
.swiper-preview-pagination .swiper-pagination-bullet-active {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 6px)) !important;
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 6px)) !important;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px) !important;
} */

/* List co-owner */

.assign-menu-action-sheet-modal {
  --height: 'auto';
  --background: transparent;
}

.assign-menu-action-sheet-modal::part(handle) {
  display: none;
}

/* Time Tracking */

ion-datetime-button.weekbreakdown-datetime-picker-button::part(native) {
  background-color: transparent;
}

/* sign in page input */
.sign-in-input-custom-label .label-text {
  font-size: var(--ion-label-font-size);
  font-weight: 700;
  margin-top: -10px; /* to align with the input since ionic no longer supports changing the font size for the label */
}
