.word-wrap {
  word-wrap: break-word;
}

.word-break {
  word-break: break-word;
}

.font-8 {
  font-size: calc(8 / var(--root-size) * 1rem) !important;
}

.font-10 {
  font-size: calc(10 / var(--root-size) * 1rem) !important;
}

.font-12 {
  font-size: calc(12 / var(--root-size) * 1rem) !important;
}

.font-14 {
  font-size: calc(14 / var(--root-size) * 1rem) !important;
}

.font-16 {
  font-size: calc(16 / var(--root-size) * 1rem) !important;
}

.font-18 {
  font-size: calc(18 / var(--root-size) * 1rem) !important;
}

.font-20 {
  font-size: calc(20 / var(--root-size) * 1rem) !important;
}

.font-22 {
  font-size: calc(22 / var(--root-size) * 1rem) !important;
}

.font-24 {
  font-size: calc(24 / var(--root-size) * 1rem) !important;
}

.font-26 {
  font-size: calc(26 / var(--root-size) * 1rem) !important;
}

.font-28 {
  font-size: calc(28 / var(--root-size) * 1rem) !important;
}

.font-30 {
  font-size: calc(30 / var(--root-size) * 1rem) !important;
}

.font-32 {
  font-size: calc(32 / var(--root-size) * 1rem) !important;
}

.font-34 {
  font-size: calc(34 / var(--root-size) * 1rem) !important;
}

.font-36 {
  font-size: calc(36 / var(--root-size) * 1rem) !important;
}

.font-38 {
  font-size: calc(38 / var(--root-size) * 1rem) !important;
}

.font-40 {
  font-size: calc(40 / var(--root-size) * 1rem) !important;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.line-height-1 {
  line-height: 1.25 !important;
}

.text-transform-none {
  text-transform: none;
}

/* Utility clamp classes */
.text-clamp-1 {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-clamp-2 {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-clamp-3 {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-clamp-4 {
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
